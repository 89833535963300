<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 md12>
        <v-card>
          <!-- PESQUISA -->
          <v-toolbar flat class="transparent pt-2" v-if="!finalizado">
            <v-btn color="green" class="white--text" @click="dialog = true">
              <v-icon left dark>add</v-icon>Incluir segurado
            </v-btn>
            <v-btn
              v-if="items.length === 0"
              color="green"
              class="white--text"
              @click="dialogComp = true"
            >
              <v-icon left dark>person_add</v-icon>Importar dados do cliente
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Buscar"
              single-line
              color="primary"
            ></v-text-field>
          </v-toolbar>

          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            class="elevation-1"
            item-key="id"
            :rows-per-page-items="[5,10,20,50,100]"
            dense
          >
            <template slot="no-data">
              <div class="text-xs-center">Sem registros.</div>
            </template>
            <template v-slot:items="props">
              <tr>
                <td>{{ props.item.codigo_abreviado }}</td>
                <td>{{ props.item.nome_segurado }}</td>
                <td>{{ props.item.plano_descricao}}</td>
                <td>{{ props.item.vigencia_inicial_format ? props.item.vigencia_inicial_format : ' - '}}</td>
                <td>{{ props.item.vigencia_final_format ? props.item.vigencia_final_format : ' - '}}</td>
                <td>{{ props.item.situacao_format }}</td>
                <td class="text-xs-center">
                  <v-icon
                    v-if="!props.item.num_apolice && !finalizado"
                    small
                    class="mr-2 td-cursor"
                    slot="activator"
                    @click="editDialog(props.item)"
                    color="primary"
                  >create</v-icon>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        v-if="finalizado"
                        small
                        class="mr-2 td-cursor"
                        slot="activator"
                        @click="editDialog(props.item)"
                        color="primary"
                      >remove_red_eye</v-icon>
                    </template>
                    <span>Detalhes</span>
                  </v-tooltip>
                  <v-icon
                    v-if="!finalizado"
                    small
                    class="mr-2 td-cursor"
                    slot="activator"
                    @click="deleteSegurado(props.item)"
                    color="red"
                  >delete</v-icon>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        v-if="props.item.num_apolice && finalizado"
                        small
                        class="mr-2 td-cursor"
                        slot="activator"
                        @click="printConsultaCertificado(props.item)"
                        color="red"
                      >print</v-icon>
                    </template>
                    <span>Imprimir certificado</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        v-if="props.item.num_apolice && finalizado"
                        small
                        class="mr-2 td-cursor"
                        slot="activator"
                        @click="printConsultaSorteio(props.item)"
                        color="orange"
                      >receipt_long</v-icon>
                    </template>
                      <span>Imprimir nº do sorteio</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
            <template v-slot:no-results>
              <div class="text-xs-center">Sem resultados para a pesquisa "{{ search }}".</div>
            </template>
          </v-data-table>
          <v-toolbar flat class="transparent" v-if="!finalizado">
            <v-btn color="primary" class="white--text" @click="voltar()">Voltar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              class="white--text"
              :disabled="items.length === 0"
              @click="avancar()"
            >Avançar</v-btn>
          </v-toolbar>
        </v-card>
      </v-flex>
    </v-layout>

    <!--DIALOG DADOS COMPLEMENTARES -->
    <v-dialog v-model="dialogComp" persistent max-width="400px">
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-spacer></v-spacer>
          <v-toolbar-title class="justify-center">Dados complementares</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-form ref="formComp" v-model="validComp" lazy-validation>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12 md12>
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="date2"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :rules="campoRules"
                        required
                        persistent-hint
                        :value="computedNascimentoComp"
                        label="Data de nascimento"
                        readonly
                        v-on="on"
                        placeholder=" "
                        outline
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker2"
                      v-model="dadosComp.nascimento"
                      locale="pt-br"
                      :max="dateMax"
                      :min="dateMin"
                      no-title
                      scrollable
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 md12>
                  <v-select
                    :rules="campoRules"
                    required
                    v-model="dadosComp.sexo"
                    :items="sexos"
                    placeholder=" "
                    outline
                    label="Sexo"
                    item-value="codigo"
                    item-text="descricao"
                  ></v-select>
                </v-flex>

                <v-flex xs12 md12>
                  <v-select
                    :rules="campoRules"
                    required
                    v-model="dadosComp.plano_codigo"
                    :items="planos"
                    placeholder=" "
                    outline
                    label="Plano"
                    item-value="codigo"
                    item-text="descricao"
                    @change="changePlano"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-container>
            <v-toolbar flat class="transparent">
              <v-btn color="red" class="white--text" @click="closeDialogComp()">Cancelar</v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="green"
                class="white--text"
                :disabled="!validComp"
                @click="importCliente(dadosComp)"
              >Gravar</v-btn>
            </v-toolbar>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--DIALOG DADOS SEGURADO -->
    <v-dialog v-model="dialog" persistent max-width="950px">
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-spacer></v-spacer>
          <v-toolbar-title class="justify-center">Segurado</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs6 md6>
                  <v-text-field
                    :disabled="!!finalizado"
                    :rules="campoRules"
                    required
                    placeholder=" "
                    outline
                    label="Nome"
                    id="nome_segurado"
                    name="nome"
                    type="text"
                    v-model="segurado.nome_segurado"
                    @input="$event => segurado.nome_segurado = $event.toUpperCase()"
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 md2>
                  <v-text-field
                    :disabled="!!finalizado"
                    :rules="campoRules"
                    required
                    ref="cpf"
                    v-model="segurado.cpf"
                    label="CPF"
                    outline
                    placeholder=" "
                    mask="###.###.###-##"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4 md4>
                  <v-select
                    :disabled="!!finalizado"
                    :rules="campoRules"
                    required
                    v-model="segurado.plano_codigo"
                    :items="planos"
                    placeholder=" "
                    outline
                    label="Produto"
                    item-value="codigo"
                    item-text="descricao"
                    @change="changePlano"
                  ></v-select>
                </v-flex>
                <v-flex xs3 md3>
                  <v-menu
                    :disabled="!!finalizado"
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="date"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="!!finalizado"
                        :rules="campoRules"
                        required
                        persistent-hint
                        :value="computedNascimento"
                        label="Data de nascimento"
                        readonly
                        v-on="on"
                        placeholder=" "
                        outline
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :disabled="!!finalizado"
                      ref="picker"
                      v-model="segurado.nascimento"
                      locale="pt-br"
                      :max="dateMax"
                      :min="dateMin"
                      no-title
                      scrollable
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs2 md2>
                  <v-select
                    :disabled="!!finalizado"
                    :rules="campoRules"
                    required
                    v-model="segurado.sexo"
                    :items="sexos"
                    placeholder=" "
                    outline
                    label="Sexo"
                    item-value="codigo"
                    item-text="descricao"
                  ></v-select>
                </v-flex>
                <v-flex xs4 md4>
                  <v-text-field
                    :disabled="!!finalizado"
                    :rules="emailRules"
                    placeholder=" "
                    outline
                    label="E-mail"
                    id="email_segurado"
                    name="email"
                    type="email"
                    required
                    v-model="segurado.email"
                    @input="$event => segurado.email = $event.toLowerCase()"
                  ></v-text-field>
                </v-flex>
                <v-flex xs3 md3>
                  <v-text-field
                    :disabled="!!finalizado"
                    :rules="campoRules"
                    required
                    v-model="segurado.telefone"
                    label="Telefone"
                    outline
                    placeholder=" "
                    mask="(##) # ####-####"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 md6>
                  <v-autocomplete
                    :disabled="!!finalizado"
                    :rules="campoRules"
                    required
                    item-value="codigo"
                    item-text="nome"
                    v-model="segurado.estado_codigo"
                    :items="estados"
                    label="UF(Estado)"
                    placeholder=" "
                    outline
                    @change="changeState"
                    clearable
                    :return-object="false"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs6 md6>
                  <v-autocomplete
                    :rules="campoRules"
                    required
                    :disabled="!segurado.estado_codigo || !!finalizado"
                    item-value="codigo"
                    item-text="nome"
                    v-model="segurado.cidade_codigo"
                    :items="cidades"
                    label="Cidade"
                    placeholder=" "
                    outline
                    clearable
                    :return-object="false"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs2 md2 v-if="!!finalizado">
                  <v-text-field
                    :disabled="!!finalizado"
                    required
                    placeholder=" "
                    outline
                    label="Vigência inicial"
                    id="vigencia_inicial"
                    name="vigencia_inicial"
                    type="text"
                    v-model="segurado.vigencia_inicial"
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 md2 v-if="!!finalizado">
                  <v-text-field
                    :disabled="!!finalizado"
                    required
                    placeholder=" "
                    outline
                    label="Vigência final"
                    id="vigencia_final"
                    name="vigencia_final"
                    type="text"
                    v-model="segurado.vigencia_final"
                  ></v-text-field>
                </v-flex>

                <v-flex xs2 md2 v-if="!!finalizado">
                  <v-text-field
                    :disabled="!!finalizado"
                    required
                    placeholder=" "
                    outline
                    label="Nº Apólice"
                    id="num_apolice"
                    name="num_apolice"
                    type="text"
                    v-model="segurado.num_apolice"
                  ></v-text-field>
                </v-flex>

                <v-flex xs2 md2 v-if="!!finalizado">
                  <v-text-field
                    :disabled="!!finalizado"
                    required
                    placeholder=" "
                    outline
                    label="Nº Operação"
                    id="num_operacao"
                    name="num_operacao"
                    type="text"
                    v-model="segurado.num_operacao"
                  ></v-text-field>
                </v-flex>

                <v-flex xs2 md2 v-if="!!finalizado">
                  <v-text-field
                    :disabled="!!finalizado"
                    required
                    placeholder=" "
                    outline
                    label="Nº Sorteio"
                    id="num_sorteio"
                    name="num_sorteio"
                    type="text"
                    v-model="segurado.num_sorteio"
                  ></v-text-field>
                </v-flex>

                <v-flex xs2 md2 v-if="!!finalizado">
                  <v-text-field
                    :disabled="!!finalizado"
                    required
                    placeholder=" "
                    outline
                    label="Nº Certificado"
                    id="num_certificado"
                    name="num_certificado"
                    type="text"
                    v-model="segurado.num_certificado"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <v-toolbar flat class="transparent">
              <v-btn color="red" class="white--text" @click="closeDialog()">Cancelar</v-btn>
              <v-spacer></v-spacer>
              <v-btn
                v-if="!finalizado"
                color="green"
                class="white--text"
                :disabled="!valid || !!segurado.num_apolice"
                @click="saveSegurado(segurado)"
              >Gravar</v-btn>
            </v-toolbar>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as moment from 'moment'
const estadoModule = () => import('./../../services/estado')
const cidadeModule = () => import('./../../services/cidade')
const seguradoModule = () => import('./../../services/segurado')
const planoModule = () => import('./../../services/planos')

export default {
  name: 'ContratoSegurados',
  props: ['finalizado'],
  data () {
    return {
      search: '',
      dialogComp: false,
      dialog: false,
      validComp: true,
      valid: true,
      emailRules: [
        v => !!v || 'E-mail é obrigatório.',
        v => /.+@.+/.test(v) || 'E-mail não é válido.'
      ],
      campoRules: [
        v => !!v || 'Campo é obrigatório.',
      ],
      headers: [
        { text: 'Código', align: 'left', value: 'codigo_abreviado' },
        { text: 'Nome', align: 'left', value: 'nome_segurado' },
        { text: 'Plano', align: 'left', value: 'plano_descricao' },
        { text: 'Início', align: 'left', value: 'vigencia_inicial' },
        { text: 'Fim', align: 'left', value: 'vigencia_final' },
        { text: 'Situação', align: 'left', value: 'situacao_format' },
        { text: 'Opções', align: 'center', value: '' },
        // { text: 'Usuários', align: 'left', value: '' },
      ],
      items: [],
      sexos: [
        {
          codigo: 'M',
          descricao: 'Masculino'
        },
        {
          codigo: 'F',
          descricao: 'Feminino'
        },
        {
          codigo: 'O',
          descricao: 'Outros'
        },
      ],
      planos: [

      ],
      date: new Date().toISOString().substr(0, 7),
      date2: new Date().toISOString().substr(0, 7),
      dateMax: new Date(new Date().getFullYear() - 14, new Date().getMonth(), new Date().getDay()).toISOString().substring(0, 10),
      dateMin: new Date(new Date().getFullYear() - 65, new Date().getMonth(), new Date().getDay()).toISOString().substring(0, 10),
      menu: false,
      menu2: false,
      dadosComp: {
        contrato_codigo: null,
        nascimento: null,
        sexo: null,
        plano_codigo: null,
        plano_preco: null
      },
      filtros: {
        data_inicial: new Date().toISOString().substr(0, 10),
        data_final: new Date().toISOString().substr(0, 10)
      },
      segurado: {
        codigo: null,
        contrato_codigo: null,
        nome_segurado: null,
        nascimento: null,
        cpf: null,
        estado_codigo: null,
        cidade_codigo: null,
        sexo: null,
        email: null,
        telefone: null,
        plano_codigo: null,
        num_apolice: null,
        vigencia_inicial: null,
        vigencia_final: null,
        num_operacao: null,
        num_sorteio: null,
        num_certificado: null,
        plano_preco: null
      },
      estados: [],
      cidades: []
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    menu2 (val) {
      val && setTimeout(() => (this.$refs.picker2.activePicker = 'YEAR'))
    }
  },
  created () {
    this.$eventHub.$on('contratoSegurados-getAll', this.getAll)
  },
  beforeDestroy () {
    this.$eventHub.$off('contratoSegurados-getAll')

  },
  mounted () {
    this.getAllEstados()
    this.getAll()
    this.getPlanos()
    this.dadosComp.contrato_codigo = this.$route.params.venda
    this.segurado.contrato_codigo = this.$route.params.venda
  },
  computed: {
    computedNascimentoComp () {
      return this.dadosComp.nascimento ? moment(this.dadosComp.nascimento).format('DD/MM/YYYY') : ''
    },
    computedNascimento () {
      return this.segurado.nascimento ? moment(this.segurado.nascimento).format('DD/MM/YYYY') : ''
    },

  },
  methods: {
    novaVenda () {
      this.$router.push('/vendas/detalhes')
    },
    closeDialog () {
      this.segurado = {
        codigo: null,
        contrato_codigo: null,
        nome_segurado: null,
        nascimento: null,
        cpf: null,
        estado_codigo: null,
        cidade_codigo: null,
        sexo: null,
        email: null,
        telefone: null,
        plano_codigo: null,
        num_apolice: null,
        vigencia_inicial: null,
        vigencia_final: null,
        num_operacao: null,
        num_sorteio: null,
        num_certificado: null,
        plano_preco: null
      }
      this.segurado.contrato_codigo = this.$route.params.venda
      this.$refs.form.resetValidation()
      this.dialog = false
    },
    closeDialogComp () {
      this.dadosComp = {
        contrato_codigo: this.$route.params.venda,
        nascimento: null,
        sexo: null,
        plano_codigo: null,
        plano_preco: null
      }
      this.$refs.formComp.resetValidation()
      this.dialogComp = false
    },
    voltar () {
      this.$eventHub.$emit('contratoDetalhes-goStep', 1)
    },
    avancar () {
      this.$eventHub.$emit('contratoFaturamento-getTotal', () => true)
      this.$eventHub.$emit('contratoDetalhes-goStep', 3)
    },
    editDialog (segurado) {
      this.segurado.codigo = segurado.codigo
      this.segurado.contrato_codigo = segurado.contrato_codigo
      this.segurado.nome_segurado = segurado.nome_segurado
      this.segurado.nascimento = segurado.nascimento
      this.segurado.cpf = segurado.cpf
      this.segurado.estado_codigo = segurado.estado_codigo
      this.segurado.cidade_codigo = segurado.cidade_codigo
      this.segurado.sexo = segurado.sexo
      this.segurado.email = segurado.email
      this.segurado.telefone = segurado.telefone
      this.segurado.plano_codigo = segurado.plano_codigo
      this.segurado.vigencia_inicial = segurado.vigencia_inicial_format
      this.segurado.vigencia_final = segurado.vigencia_final_format
      this.segurado.num_apolice = segurado.num_apolice
      this.segurado.num_operacao = segurado.num_operacao
      this.segurado.num_sorteio = segurado.num_sorteio
      this.segurado.num_certificado = segurado.num_certificado
      this.segurado.plano_preco = segurado.plano_preco
      this.getAllCidades(this.segurado.estado_codigo)
      this.dialog = true
    },
    async importCliente (payload) {
      if (!this.$refs.formComp.validate()) {
        return
      }
      let seguradoService = await seguradoModule()
      payload.contrato_codigo = this.$route.params.venda
      let resp = await seguradoService.importByCliente(this.$axios, { ...payload })
      if (resp.status === 200 && resp.data.success) {
        this.closeDialogComp()
        this.$toast.success('Sucesso ao incluir o registro')
        await this.getAll()
      }
    },
    async saveSegurado (payload) {
      if (!this.$refs.form.validate()) {
        return
      }
      if (payload.codigo) {
        let seguradoService = await seguradoModule()
        payload.contrato_codigo = this.$route.params.venda
        let resp = await seguradoService.editSegurado(this.$axios, { ...payload })
        if (resp.status === 200 && resp.data.success) {
          this.closeDialog()
          this.$toast.success('Sucesso ao editar o registro')
          await this.getAll()
        }
      } else {
        let seguradoService = await seguradoModule()
        let resp = await seguradoService.addSegurado(this.$axios, { ...payload })
        if (resp.status === 200 && resp.data.success) {
          this.closeDialog()
          this.$toast.success('Sucesso ao incluir o registro')
          await this.getAll()
        }
      }
    },
    async deleteSegurado (payload) {
      let confirm = await this.$swal({
        title: 'Atenção',
        text: `Deseja realmente fazer a exclusão deste registro?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não'
      })
      if (confirm.value) {
        let seguradoService = await seguradoModule()
        let resp = await seguradoService.deleteSegurado(this.$axios, payload.codigo)
        if (resp.status === 200 && resp.data.success) {
          this.$toast.success('Sucesso ao excluir o registro')
          await this.getAll()
        }
      }
    },
    async getAllEstados () {
      let estadoService = await estadoModule()
      let resp = await estadoService.getAll(this.$axios)
      if (resp.status === 200 && resp.data) {
        this.estados = resp.data
      }
    },
    async getAllCidades (estado_codigo) {
      let cidadeService = await cidadeModule()
      let resp = await cidadeService.getByEstado(this.$axios, estado_codigo)
      if (resp.status === 200 && resp.data) {
        this.cidades = resp.data
      }
    },
    async changeState (estado_codigo) {
      await this.getAllCidades(estado_codigo)
    },
    async changePlano (plano_codigo) {
      let plano
      for (let p of this.planos) {
        if (p.codigo === plano_codigo) {
          plano = p
        }
      }
      this.segurado.plano_preco = plano.preco
      this.dadosComp.plano_preco = plano.preco
    },
    async getAll () {
      if (this.$route.params.venda) {
        let seguradoService = await seguradoModule()
        let resp = await seguradoService.getByContrato(this.$axios, this.$route.params.venda)
        if (resp.status === 200 && resp.data) {
          this.items = resp.data
        }
      }
    },
    async getPlanos () {
      let planoService = await planoModule()
      let resp = await planoService.getAll(this.$axios)
      if (resp.status === 200 && resp.data) {
        this.planos = resp.data
      }
    },
    async printConsultaCertificado (segurado) {
      let seguradoService = await seguradoModule()
      let resp = await seguradoService.printCertificado(this.$axios, { segurado_codigo: segurado.codigo })
      if (resp.status === 200 && resp.data) {
        let blob = resp.data
        let link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `Safir - Certificado - ${segurado.nome_segurado}.pdf`
        document.body.append(link)
        link.click()
        link.remove()
        window.addEventListener('focus', () => URL.revokeObjectURL(link.href), { once: true })
      }
    },
    async printConsultaSorteio (segurado) {
      let seguradoService = await seguradoModule()
      let resp = await seguradoService.printSorteio(this.$axios, { segurado_codigo: segurado.codigo })
      if (resp.status === 200 && resp.data) {
        let blob = resp.data
        let link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `Safir - Sorteio - ${segurado.nome_segurado}.pdf`
        document.body.append(link)
        link.click()
        link.remove()
        window.addEventListener('focus', () => URL.revokeObjectURL(link.href), { once: true })
      }
    },
  }
}
</script>